import React, { Component } from "react";
import "./Footer.css";

class BusinessCard extends Component {
    render() {
        return (
            <div className="footer">
                <div className="card-content">
                    <div className="card-header">
                        <h1>Arsh Kadakia</h1>
                        <h2>Sales Representative</h2>
                    </div>
                    <div className="card-body">
                        <p>Homelife G1 Realty Inc. Brokerage</p>
                        <p>2260 Bovaird Dr E #202, Brampton, ON L6R 3J5</p>
                    </div>
                    <div className="card-footer">
                        <div className="contact-info">
                            <p>OFFICE: (905) 793-7797</p>
                            <p>CELL: (647) 766-9515</p>
                        </div>
                        <div className="company-logo">
                            <img src="https://www.homelifeg1realty.com/files/themeManager/8109/theme14/Homelife%20G1%20new%20logo%20black.png" alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BusinessCard;