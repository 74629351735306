import React from 'react';
import './WelcomePage.css';

const WelcomePage = () => {
    return (
        <div className="welcome-container">
            <header className="welcome-header">
                <h1>Welcome to Arsh Kadakia's Website</h1>
                <p>Discover Your Dream Home</p>
            </header>

            {/*<section className="explore-neighborhoods">*/}
            {/*    <h2>Explore Neighborhoods</h2>*/}
            {/*    <p>Discover the community that fits your lifestyle.</p>*/}
            {/*    /!* Example neighborhoods *!/*/}
            {/*    <div className="neighborhood-list">*/}
            {/*        <div className="neighborhood-card">*/}
            {/*            <h3>Lakeview Terrace</h3>*/}
            {/*            <p>Tranquil and scenic, perfect for families.</p>*/}
            {/*        </div>*/}
            {/*        <div className="neighborhood-card">*/}
            {/*            <h3>Urban Heights</h3>*/}
            {/*            <p>Vibrant city living with a modern touch.</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            {/*<section className="about-us">*/}
            {/*    <h2>About Arsh</h2>*/}
            {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>*/}
            {/*</section>*/}
        </div>
    );
};

export default WelcomePage;
