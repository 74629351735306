import './App.css';
import Footer from "./components/Footer/Footer";
import NavBar from "./components/Navigation/NavBar";
import AppRoutes from './routes';
import {BrowserRouter} from "react-router-dom"; // or the appropriate path to your routes file

function App() {
  return (
      <BrowserRouter>
        <NavBar />
          <div className="app">
              <AppRoutes />
          </div>
        <Footer />
      </BrowserRouter>
  );
}

export default App;
