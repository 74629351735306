import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from "./components/Authentication/Login";
import Signup from "./components/Authentication/Signup";
import EmailVerification from "./components/Authentication/EmailVerification";
import SearchPage from "./components/Search/Search"; // Import the SearchPage component
import ListingPage from "./components/Search/ListingPage";
import WelcomePage from "./components/Home/WelcomePage"; // Import the ListingPage component

const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/search" element={<SearchPage />} /> {/* Add a route for the SearchPage */}
        <Route path="/listings/:id" element={<ListingPage />} />
        {/* Add other routes here */}
    </Routes>
  );
};

export default AppRoutes;