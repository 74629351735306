import React from 'react';
import './NavBar.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import js-cookie
import api from "../../apiConfig";

const NavBar = () => {
    // Function to handle logout
    const handleLogout = async () => {
        // Make POST request to /logout
        // Include access token and refresh token in request headers
        await api.post('/logout', {}, {
            headers: {
                "X-Access-Token": Cookies.get('access_token'),
                "X-Refresh-Token": Cookies.get('refresh_token')
            }
        });
        // Clear cookies
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        window.location.reload();
    };

    return (
        <nav className="navbar">
            <div className="nav-logo">
                Arsh Kadakia {/* Replace with your logo or site name */}
            </div>
            <ul className="nav-links">
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/search'}>Search</Link></li>
                {/* Add other navigation links as needed */}
            </ul>
            <div className="nav-logout nav-links">
                {Cookies.get('access_token') && Cookies.get('refresh_token') ? (
                    // Show logout as a link
                    <Link to={'/'} onClick={handleLogout}>Logout</Link>
                ) : (
                    // Else show login link
                    <Link to={'/login'}>Login</Link>
                )}
            </div>
        </nav>
    );
}

export default NavBar;
