import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "./Login.css";
import api from '../../apiConfig';
import Cookies from "js-cookie";

function Login() {
  const navigate = useNavigate(); // Initialize useNavigate
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    if (event.target.name === 'username') {
      setUsername(event.target.value);
    } else if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post('/login', {
        username: username,
        password: password,
      }, {
        withCredentials: true
      });
      const { access_token, refresh_token } = response.data;
      // Set cookies
      Cookies.set('access_token', access_token, { expires: 1 / 24 });
      Cookies.set('refresh_token', refresh_token, { expires: 7 });

      navigate('/search');
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Incorrect E-mail or Password');
      }
    }
  }



  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Log in</h2>
        {errorMessage && <div className="error-banner">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input type="text" name="username" placeholder="Username" required onChange={handleInputChange} />
          </div>
          <div className="input-group">
            <input type="password" name="password" placeholder="Password" required onChange={handleInputChange} />
          </div>
          <button type="submit">Log in</button>
          <p className="signup-text">or, <a href="/signup">sign up</a></p>
        </form>
      </div>
    </div>
  );
}

export default Login;