import React, { useState, useEffect, useRef } from 'react';
import SearchBar from "./SearchBar";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import api from "../../apiConfig";
import "./Search.css";

// Placeholder data for listings
const placeholderListings = [
];

const containerStyle = {
  width: '100%',
  height: '400px'
};

// Set a default center for the map
const center = {
  lat: 43.651070,
  lng: -79.347015
};

const ListingCard = ({ listing }) => {
  // Determine the image URL or use a placeholder
  // const imageUrl = listing.Media && listing.Media.length > 0
  //   ? listing.Media[0].MediaURL
  //   : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/660px-No-Image-Placeholder.svg.png?20200912122019"; // Placeholder image URL

  return (
    <div className="listing-card">
      {/*<img*/}
      {/*  src=""*/}
      {/*  alt="House"*/}
      {/*  style={{ width: '100px', height: '100px' }} // Inline styles for image size*/}
      {/*/>*/}
      <div className="listing-details">
        <h2>{listing["Lp_dol"]}</h2>
        <p>{listing["Addr"]}</p>
        <p>{listing["Br"]} BR | {listing["Bath_tot"]} BA</p>
      </div>
      <a href={`/listings/${listing["Ml_num"]}`}>
        <button>View Listing</button>
      </a>
    </div>
  );
};

const SearchPage = () => {
  const [listings, setListings] = useState([]);
  const [mapBounds, setMapBounds] = useState({});
  const mapRef = useRef(null); // Create a ref for the map

  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');

  useEffect(() => {
    setListings(placeholderListings);
  }, []);


  const updateSearchParameters = (params) => {
    setMinPrice(params.minPrice);
    setMaxPrice(params.maxPrice);
    setBedrooms(params.bedrooms);
    setBathrooms(params.bathrooms);
  };


  const handleSearch = () => {
    // Check if mapBounds is set and has the necessary properties
    if (!mapBounds.ne || !mapBounds.sw) {
      console.log("Map bounds are not set yet.");
      return;
    }

    // Start with the map bounds part of the search term
    let searchTerm = `lat1=${mapBounds.sw.lat}&lat2=${mapBounds.ne.lat}&lng1=${mapBounds.sw.lng}&lng2=${mapBounds.ne.lng}`;

    // Conditionally add other parameters if they have valid values
    if (minPrice) searchTerm += `&minPrice=${minPrice}`;
    if (maxPrice) searchTerm += `&maxPrice=${maxPrice}`;
    if (bedrooms) searchTerm += `&bedrooms=${bedrooms}`;
    if (bathrooms) searchTerm += `&bathrooms=${bathrooms}`;
    console.log(searchTerm);

    api.get(`/vow_homes/api?${searchTerm}`).then(
      (response) => {
          setListings(response.data);
      }
    );
  };

  const handleMapIdle = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        setMapBounds({
          ne: { lat: ne.lat(), lng: ne.lng() },
          sw: { lat: sw.lat(), lng: sw.lng() }
        });
      }
    }
  };

  // Convert listings to markers (assuming listings have lat and lng properties)
  const markers = listings.map(listing => ({
    id: listing.id,
    position: {
      lat: listing.lat,
      lng: listing.lng   // Replace with actual longitude
    }
  }));

  const renderListings = () => {
    if (listings.length === 0) {
      return <div className="no-listings-message">Search for your home of interest!</div>;
    } else {
      return listings.map(listing => (
        <ListingCard key={listing.id} listing={listing} />
      ));
    }
  };

  return (
    <div className="search-page">
      <SearchBar onSearch={handleSearch} onUpdateSearchParameters={updateSearchParameters} />
      <div className="content-container" style={{ display: 'flex' }}>
        <div className="listings-container" style={{ flex: 3, height: '400px', overflowY: 'auto' }}>
          {renderListings()}
        </div>
        <div className="map-container" style={{ flex: 2 }}>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} // Replace with your Google Maps API Key
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={(map) => mapRef.current = map}
                onIdle={handleMapIdle}
            >
              {markers.map(marker => (
                <Marker key={marker.id} position={marker.position} />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
