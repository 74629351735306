import React, {useEffect, useState} from 'react';

const SearchBar = ({ onSearch, onUpdateSearchParameters }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleMinPriceChange = (e) => setMinPrice(e.target.value);
  const handleMaxPriceChange = (e) => setMaxPrice(e.target.value);
  const handleBedroomsChange = (e) => setBedrooms(e.target.value);
  const handleBathroomsChange = (e) => setBathrooms(e.target.value);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);

  useEffect(() => {
    if (searchButtonClicked) {
      onSearch(searchTerm);
      setSearchButtonClicked(false);
    }
  }, [onSearch, searchButtonClicked, searchTerm]);

  const handleSearchClick = () => {
    onUpdateSearchParameters({ minPrice, maxPrice, bedrooms, bathrooms }); // Update the search parameters in parent component
    setSearchButtonClicked(true);
  };

  return (
    <div className="search-bar" style={{ margin: '20px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
        <input
          type="text"
          placeholder="Zoom in to part of map of interest."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: '70%', marginRight: '10px', padding: '10px', fontSize: '1rem' }}
        />
        <button onClick={handleSearchClick} style={{ padding: '10px 20px', fontSize: '1rem', width: '30%' }}>
          Search
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <input
          type="text"
          placeholder="Min Price"
          value={minPrice}
          onChange={handleMinPriceChange}
          style={{ marginRight: '10px', padding: '10px', fontSize: '1rem', width: '30%' }}
        />
        <input
          type="text"
          placeholder="Max Price"
          value={maxPrice}
          onChange={handleMaxPriceChange}
          style={{ marginRight: '10px', padding: '10px', fontSize: '1rem', width: '30%' }}
        />
        <input
          type="number"
          placeholder="Bedrooms"
          value={bedrooms}
          onChange={handleBedroomsChange}
          style={{ marginRight: '10px', padding: '10px', fontSize: '1rem', width: '30%' }}
        />
        <input
          type="number"
          placeholder="Bathrooms"
          value={bathrooms}
          onChange={handleBathroomsChange}
          style={{ padding: '10px', fontSize: '1rem', width: '30%' }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
