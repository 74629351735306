import React, { useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./EmailVerification.css";
import api from "../../apiConfig";

const EmailVerification = () => {
  const location = useLocation();
  const username = location.state?.username || "";

  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(''); // Add this line

  const navigate = useNavigate();

  const resendVerificationCode = async () => {
    try {
      const response = await api.post('/send-verification-code', {}, {
        withCredentials: true
      });
      const data = response.data;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  const verifyEmail = (event) => {
    event.preventDefault();
    console.log("Username:", username);
    console.log("Verification code:", verificationCode);
    api.post('/verify-email', {
      username: username,
      code: verificationCode
    }, {
      withCredentials: true
    })
    .then(response => {
      setErrorMessage(''); // Clear the error message
      navigate('/login');
    })
    .catch(error => {
      setErrorMessage('Email verification failed'); // Update this line
    });
  }

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  }

  return (
    <div className="email-verification-container">
      <div className="email-verification-form">
        <h2>Email Verification</h2>
        <p>Please enter the verification code sent to your email address.</p>
        {errorMessage && <div className="error-banner">{errorMessage}</div>} {/* Add this line */}
        <form>
          <div className="input-group">
            <input
              type="text"
              placeholder="Verification Code"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              required
            />
          </div>
          <button type="submit" onClick={(event) => verifyEmail(event)}>
            Verify Email
          </button>
          <button type="button" onClick={resendVerificationCode} className="resend-code">
            Resend Code
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailVerification;