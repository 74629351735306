import React, { useState, useEffect } from "react";
import api from '../../apiConfig';
import "./Signup.css";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Modal = ({ children, show, handleClose }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};


const Signup = () => {
  const [state, setState] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const [termsVisible, setTermsVisible] = useState(false); // New state for the terms overlay visibility
  const [agreeTerms, setAgreeTerms] = useState(false); // New state for the checkbox

  const handleTermsChange = (event) => {
    setAgreeTerms(event.target.checked);
  }


  // Extracted function for checking existing tokens
  // const checkTokensAndRedirect = () => {
  //   const accessToken = Cookies.get('access_token');
  //   const refreshToken = Cookies.get('refresh_token');
  //   console.log('Access Token:', accessToken);
  //   console.log('Refresh Token:', refreshToken);
  //   if (accessToken && refreshToken) {
  //     navigate('/');
  //   }
  // };


  useEffect(() => {
    const accessToken = Cookies.get('access_token');
    const refreshToken = Cookies.get('refresh_token');
    if (accessToken && refreshToken) {
      console.log('Tokens exist, navigating...');
      navigate('/');
    }
  }, [navigate]);
  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const passwordRegex = /^(?=.*[!@#$%^&.*])[A-Za-z\d!@#$%^&.*]{7,}$/; // Add this line

    if (!emailRegex.test(state.email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    if (!passwordRegex.test(state.password)) { // Add this block
      setErrorMessage('Password must be at least 7 characters and include a special symbol');
      return;
    }
    if (state.password !== state.confirmPassword) {
      setErrorMessage('Password and confirm password do not match');
      return;
    }

    if (!agreeTerms) {
      setErrorMessage('You must agree to the terms of use');
      return;
    }

    api.post('/signup', {
      username: state.username,
      password: state.password,
      email: state.email
    }, {
      withCredentials: true
    })
    .then(() => {
      setErrorMessage('');
      navigate('/verify-email', {state: state});
    })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('Something went wrong. Please try again later.');
      }
    });
  }

  const text_blurb = "(a)\tConsumers must provide their name and a valid email address. The Member must send an email to the address provided by the Consumer confirming that the Consumer has agreed to the Terms of Use (described in paragraph 7(c) below). The Consumer may be permitted to access the VOW only after the Member has verified that the email address provided is valid and that the Consumer agreed to the Terms of Use.\n" +
      "(b)\tThe Consumer must supply a username and a password, the combination of which must be different from those of all other Consumers on the VOW, before being permitted to search and retrieve Listing Information via the VOW. The username and password may be established by the Consumer or may be supplied by the Member, at the option of the Member. An email address may be associated with only one username and password. Consumers' passwords may be valid for up to 90 days, after which such passwords must be renewed or reconfirmed. The Member must, at all times, maintain a record of the name and email address supplied by the Consumer, and the username and current password of each Consumer. Such records must be kept for not less than 180 days after the expiration of the validity of the Consumer's password. If TREB has reason to believe that a Member's VOW has been the cause of, or permitted a breach in, the security of the MLS® data or a violation of this VOW Policy, or the MLS® Rules and Policies (including the VOW Rules) related to use by one or more Consumers, the Member shall, upon request of TREB, provide to TREB a copy of the record of the name, email address, username, current password, and audit trail, if required, of any Consumer identified by TREB to be suspected of involvement in the breach or violation.\n" +
      "(c)\tThe Consumer must be required to affirmatively express agreement to a \"Terms of Use\" agreement that requires the Consumer to open and review an agreement that provides at least the following:\n" +
      "a.\tThat the Consumer acknowledges entering into a lawful broker-consumer relationship with the Member.\n" +
      "b.\tThat all MLS® data obtained from the VOW is intended only for the Consumer's personal, non-commercial use.\n" +
      "c.\tThat the Consumer has a bona fide interest in the purchase, sale, or lease of real estate of the type being offered through the VOW.\n" +
      "d.\tThat the Consumer will not copy, redistribute, retransmit, or otherwise use any of the data or Listing Information provided, except in connection with the Consumer's consideration of the purchase, sale, or lease of an individual property. (v) That the Consumer acknowledges TREB' s ownership of, and the validity of TREB's proprietary rights and copyright in the MLS® database, MLS® data, TREB's MLS® System, and Listing Information.\n" +
      "e.\tThat the Consumer will not, directly or indirectly, display, post, disseminate, distribute, publish, broadcast, transfer, sell or sublicense any Listing Information to another individual or entity. The prohibited uses expressly include \"scraping\" (including \"screen scraping\" and \"database scraping\"), \"data mining\" or any other activity intended to collect, store, re-organize, summarize or manipulate any Listing Information or any related data.\n" +
      "f.\tAfter the Consumer has opened for viewing the Terms of Use agreement, a \"mouse click\" is sufficient to acknowledge agreement to those terms. The Terms of Use agreement may not impose a financial obligation on the Consumer or create any representation agreement between the Consumer and the Member.\n" +
      "g.\tThe Terms of Use agreement shall also expressly authorize TREB, and other TREB Members or their duly authorized representatives, to access the VOW for the purposes of verifying compliance with MLS® Rules and Policies (including the VOW Rules) and monitoring the display of Members' Listings by the VOW.\n" +
      "h.\tEvery VOW must display a privacy policy that boldly informs Consumers of, and obtains Consumers' consent to, all the ways in which Personal Information that they provide may be collected, used or disclosed, including the fact that Personal Information may be shared with TREB for auditing and/or legal purposes.\n"

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h2>Sign Up</h2>
        {errorMessage && <div className="error-banner">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input type="text" name="username" placeholder="Username" required onChange={handleInputChange} />
          </div>
          <div className="input-group">
            <input type="email" name="email" placeholder="Email" required onChange={handleInputChange} />
          </div>
          <div className="input-group">
            <input type="password" name="password" placeholder="Password" required onChange={handleInputChange} />
          </div>
          <div className="input-group">
            <input type="password" name="confirmPassword" placeholder="Confirm Password" required onChange={handleInputChange} />
          </div>
          <div className="input-group terms">
            <input type="checkbox" id="agreeTerms" checked={agreeTerms} onChange={handleTermsChange} />
            <label htmlFor="agreeTerms">I agree to the <span className="terms-link" onClick={() => setTermsVisible(true)}>Terms of Use</span></label>
          </div>
          <button type="submit">Sign Up</button>
          <p className="login-text">Already have an account? <a href="/login">Log in</a></p>
        </form>
      </div>
      <Modal show={termsVisible} handleClose={() => setTermsVisible(false)}>
        <h2>Terms of Use</h2>
        <div className="terms-text"
          dangerouslySetInnerHTML={{ __html: text_blurb || 'Loading...' }}>
        </div>
      </Modal>
    </div>
  );
}

export default Signup;